import { NavLink } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { formatVND } from "@/shared/utils/ultils";
import dayjs from "dayjs";
import Nav from "./Nav";
import FooterCustom from "./FooterCustom";
import avatar from '../../../assets/img/avatar_2.jpg'

const TaiKhoan = () => {
    const { user } = useSelector((state: RootState) => state.authentication)


    return <div className="container-web page-rut-tien-web">
        <Header />
        <div className='content-header'>

            <Nav />

            <div className="header-bottom">

                <div className="top d-flex">
                    <NavLink to={'/tai-khoan'}>Thông tin cơ bản</NavLink>
                    <NavLink to={'/the-ngan-hang'}>Quản lí tài khoản ngân hàng</NavLink>
                </div>

                <div className="p-3 d-flex gap-3">
                    <div>
                        <img className="avatar-top" width={107} height={107} style={{ borderRadius: "50%" }} src={avatar} />
                    </div>
                    <div className="p-3 d-flex w-100 justify-content-between info-right">

                        <div className="d-flex flex-column">
                            <div>{user?.username}</div>
                            <div>Số dư: <span style={{ color: "#f49400" }}>{formatVND(user?.money ?? 0)}</span></div>
                            <div>Biệt danh: {user?.username}</div>
                        </div>

                        <div className="d-flex flex-column">
                            <div>Cấp hội viên: VIP{user?.vip}</div>
                            <div></div>
                            <div>Mức Độ An Toàn</div>
                        </div>

                        <div className="d-flex align-items-center align-items-center">
                            <div>Thời gian đăng nhập gần đây: { dayjs(user?.lastModifiedDate ?? 0).format('DD/MM/YYYY HH:mm') }</div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div className='page-body-web'>
            {/* <Sidebar /> */}
        </div>

        <FooterCustom />

    </div>

}
export default TaiKhoan;