import {
    CButton,
    CCol,
    CForm,
    CFormCheck,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CInputGroup,
    CInputGroupText,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
    ResponseStatus,
    ResponseVip,
    mapResponseStatus,
    mapResponseVip,
    responseStatusArray,
    responseVipArray,
} from '../../../shared/enumeration/ResponseStatus'
import { IParams, ISelectValue } from '../../../shared/shared-interfaces'
import SModal from '../../shared/Modal/SModal'
import EyeIcon from '../../shared/icons/EyeIcon'
import EyeOffIcon from '../../shared/icons/EyeOffIcon'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import { INewUser, IUser } from '../../../shared/model/user.model'
import { AppDispatch } from '../../../store'
import { ToastSuccess } from '../../shared/toast/Toast'
import {
    changeMoneyEntity,
    createEntity,
    updateEntity,
} from './usersManagement.api'
import { fetching, resetEntity, userSelectors } from './usersManagement.reducer'
import { permissionSelectors } from '../SystemSetting/PermissionGroup/permission.reducer'
import {
    mapRoleToString,
    SystemRole,
    systemRoleArray,
} from '@/shared/enumeration/role'
import Select, { SingleValue } from 'react-select'
import { IPermission } from '@/shared/model/permission.model'
import { formatVND } from '@/shared/utils/ultils'


interface IUserUpdateProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    userObj?: IUser
}

interface IChangeMoney {
    money: number,
    method: "add" | "sub"
}

const ChangeMoney = (props: IUserUpdateProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const { initialState } = useSelector((state: RootState) => state.usersReducer)
    const { user } = useSelector((state: RootState) => state.authentication)
    const { changeEntitySuccess } = initialState
    const { visible, setVisible, userObj } = props
    const handleOnClose = () => {
        setVisible(false)
    }


    useEffect(() => {
        if (changeEntitySuccess) {
          setVisible(false)
          ToastSuccess('Cập nhật số tiền thành công')
          dispatch(resetEntity())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeEntitySuccess])


    const initialValues: IChangeMoney = {
        money: 0,
        method: "add"
    }


    const validationSchema = Yup.object().shape({
        money: Yup.number().required('Không được để trống').nullable(),
    })

    return <div>

        <SModal
            visible={visible}
            onClose={handleOnClose}
            backdrop="static"
            className="custom-modal"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                // validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(value) => {
                    console.log(value);
                    dispatch(fetching())
                    if (userObj) {
                        dispatch(changeMoneyEntity({...value, id: userObj?.id}))
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue,
                }) => (
                    <CForm onSubmit={handleSubmit} className="custom-form">
                        <>
                            <CModalHeader>
                                <h5>Thêm hoặc trừ số tiền cho tài khoản: <strong> {userObj?.username}</strong></h5>
                            </CModalHeader>
                            <CModalBody>
                                <div className='mb-3'>Số tiền hiện tại: {formatVND(userObj?.money ?? 0)}</div>
                                <CRow className="g-4">
                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Số tiền
                                        </CFormLabel>

                                        <CFormInput
                                            className="form-height-44"
                                            name="money"
                                            autoComplete="off"
                                            value={values.money}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.money && touched.money}
                                        />
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.money && touched.money
                                                    ? 'd-block'
                                                    : 'd-none'
                                            }
                                        >
                                            {errors.money}
                                        </CFormFeedback>
                                    </CCol>

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Cách thức
                                        </CFormLabel>


                                        <CFormCheck name="method"  type='radio' label='Cộng tiền' value='add' checked={values.method === "add"} onChange={(e) => setFieldValue('method', 'add')} />
                                        <CFormCheck name="method"  type='radio' label='Trừ tiền' value='sub' checked={values.method === "sub"} onChange={(e) => setFieldValue('method', 'sub')} />

                                    </CCol>
                                </CRow>
                            </CModalBody>
                            <CModalFooter className="d-flex justify-content-end">
                                <CButton
                                    className="btn-custom minw-120 variant-gray-300"
                                    type="button"
                                    onClick={() => {
                                        resetForm()
                                        setVisible(false)
                                    }}
                                >
                                    Huỷ
                                </CButton>
                                <CButton
                                    className="btn-custom minw-120 primary-500"
                                    type="submit"
                                >
                                    Xác nhận
                                </CButton>
                            </CModalFooter>
                        </>
                    </CForm>
                )}
            </Formik>

        </SModal>

    </div>
}

export default ChangeMoney;


