import { RootState } from '@/reducers'
import { IGame } from '@/shared/model/game.model'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../shared/config/axios-interceptor'
import { getProfile } from '../auth/auth.api'
import { formatVND } from '@/shared/utils/ultils'
import SModal from '@/components/shared/Modal/SModal'
import { CButton, CModalBody, CModalFooter } from '@coreui/react-pro'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'
import { mapBiDatCuocGroup } from '@/shared/enumeration/ResponseStatus'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import KyQuay from './KyQuay'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { getEntities } from '../HistoryManagement/historyManagement.api'
import { log } from 'console'
import { historySelectors } from '../HistoryManagement/historyManagement.reducer'
import { IHistory } from '@/shared/model/history.model'
import { IGameName } from '@/shared/model/gameName.model'
import { IGameSidebar } from '@/shared/model/gameSidebar.model'

import RenderImageF1 from './RenderImageF1'
import Header from './components/Header'
import { HistoryState } from '@/shared/enumeration/historyState'

interface IProp {
  game: IGameName
}

const EsportF1 = (props: IProp) => {
  const dispatch = useDispatch<AppDispatch>()
  const { params } = useRouter()
  const { game } = props;

  const [tab, setTab] = useState<number>(1)
  const [chonCuoc, setChonCuoc] = useState<IBiDatCuoc[]>([])
  const [soTien, setSoTien] = useState<number>(100)
  const [boxCuoc, setBoxCuoc] = useState<IGameSidebar[]>([])
  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)
  const [ky, setKy] = useState<IGame | null>(null)
  const [load, setLoad] = useState(false)
  const [disableDatCuoc, setDisableDatCuoc] = useState(false)
  const { user } = useSelector((state: RootState) => state.authentication)
  const historys = useSelector(historySelectors.selectAll)

  const [showModalCuoc, setShowModalCuoc] = useState(false)

  const [tabLeft, setTabLeft] = useState(0)

  const { initialState } = useSelector(
    (state: RootState) => state.historyReducer,
  )
  const { filterState } = initialState

  const [listKetQua, setListKetQua] = useState<IGame[]>([])

  const getListKetQua = async () => {
    try {
      const response = await axios.get(
        '/draws?gameId=' +
          params?.id +
          '&status=closed&sortBy=id&sortOrder=DESC',
      )
      setListKetQua(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const datCuocHandle = (object: any) => {
    if (chonCuoc.includes(object)) {
      let a = chonCuoc.filter(
        (item: any) => item.biDatCuocId !== object.biDatCuocId,
      )
      setChonCuoc(a)
    } else {
      setChonCuoc([...chonCuoc, object])
    }
  }

  const getBoxCuoc = async () => {
    try {
      const response = await axios.get('/bidatcuoc/listcuoc/' + params?.id)
      setBoxCuoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current/' + params?.id)
      setKy(response.data)
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get(
        '/draws/get-result-ket-qua-ky-truoc/' + params?.id,
      )
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getListKetQua()
    getBoxCuoc()
    getKy()
    getKetQuaKyTruoc()
    dispatch(getProfile())
    dispatch(
      getEntities({
        ...filterState,
        userId: Number(user?.id) ?? 1,
        sortBy: 'historyId',
        sortOrder: 'DESC',
        status: null,
        state: HistoryState.SHOW
      }),
    )
  }, [load, game])

  // const

  const handleOnClose = () => {
    setShowModalCuoc(false)
  }

  const handleOnShow = () => {
    if (chonCuoc.length === 0) {
      ToastError('Vui lòng chọn ít nhất 1 mục cược')
      return
    }
    setShowModalCuoc(true)
  }

  const submitHanler = async () => {
    try {
      if ((user?.money ?? 0) < soTien * chonCuoc.length) {
        ToastError('Số dư không đủ')
        return
      }

      if (soTien < 10) {
        ToastError('Số tiền cược phải lớn hơn 10')
        return
      }

      const data = {
        money: soTien,
        kyId: ky?.id,
        listDatCuoc: chonCuoc,
      }
      const response = await axios.post('/history/create-all', data)
      if (response.data) {
        setShowModalCuoc(false)
        ToastSuccess('Mua vé thành công')
        setLoad(!load)
        setChonCuoc([])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const renderKQ = (item: any) => {
    if (item.isWin || item.isWin == 0) {
      if (item.isWin == 1) {
        return (
          <div className="text-success">
            Đã trúng thưởng: {formatVND(item.addMoney ?? 0)}
          </div>
        )
      }
      return <div className="text-danger">Không trúng thưởng</div>
    }
    return <div className="text-warning">Chưa mở thưởng</div>
  }

  return (
    <div className="page-wap-game d-flex flex-column">
      <Header game={game} />
      <KyQuay
        load={load}
        ky={ky}
        setDisableDatCuoc={setDisableDatCuoc}
        setLoad={setLoad}
      />

      <div className="ketqua ketqua-f1 d-flex justify-content-between align-items-center">
        <div style={{ color: '#4f3b70', fontSize: 13 }}>
          Kỳ {ketQuaKyTruoc?.id}
        </div>
        <div className="d-flex box-items">
          {Array.from({ length: game?.bi ?? 10 }, (_, i) => i + 1).map(
            (_, index) => {
              const propertyName: any = `ball${index + 1}`
              const ball = ketQuaKyTruoc?.[propertyName as keyof IGame] ?? 0
              return (
                <div key={index} className={'item'}>
                  <RenderImageF1 ball={Number(ball)} />
                </div>
              )
            },
          )}
        </div>
      </div>

      <div className="main-content-game">
        <div className="header-tab">
          <div className={tab == 1 ? 'active' : ''} onClick={() => setTab(1)}>
            Tham gia
          </div>
          <div className={tab == 2 ? 'active' : ''} onClick={() => setTab(2)}>
            Bản đồ
          </div>
          <div className={tab == 3 ? 'active' : ''} onClick={() => setTab(3)}>
            Kỷ lục
          </div>
          <div className={tab == 4 ? 'active' : ''} onClick={() => setTab(4)}>
            Lịch sử
          </div>
        </div>

        {tab == 1 && (
          <>
            <div
              className="tab tab1 d-flex"
              style={{ height: window.innerHeight - 279 }}
            >
              <div className="sidebar-tab1">
                <div
                  className={tabLeft === 0 ? 'active' : ''}
                  onClick={() => setTabLeft(0)}
                >
                  Hai mặt
                </div>

                {game?.gameSidebar.map((item: IGameSidebar, index: number) => {
                  return (
                    <div
                      key={index}
                      className={tabLeft === item?.id ? 'active' : ''}
                      onClick={() => setTabLeft(item?.id)}
                    >
                      {item.name}
                    </div>
                  )
                })}
              </div>
              <div className="content-tab1">
                {boxCuoc.map((item: IGameSidebar, index: number) => {
                  return (
                    (tabLeft === item.id || tabLeft === 0) && (
                      <div className="box-item box-top box-item-f1" key={index}>
                        <h6 className="box-heading">{item.name}</h6>
                        <div className="row g-2">
                          {item.biDatCuoc.map(
                            (item2: IBiDatCuoc, index2: number) => {
                              return (
                                <div className="col-3" key={index2}>
                                  <div
                                    className={
                                      chonCuoc.includes(item2)
                                        ? 'item active'
                                        : 'item'
                                    }
                                    onClick={() => datCuocHandle(item2)}
                                  >
                                    <div className="name">
                                      <RenderImageF1
                                        width={41}
                                        height={41}
                                        ball={Number(item2.group)}
                                      />
                                    </div>
                                    <div className="rate">{item2.rate}</div>
                                  </div>
                                </div>
                              )
                            },
                          )}
                        </div>
                      </div>
                    )
                  )
                })}
              </div>
            </div>

            <div className="footer-custom-game">
              <div className="footer-top">
                <div className="footer-top-left">
                  <div className="item1" onClick={() => setSoTien(10)}>
                    10
                  </div>
                  <div className="item2" onClick={() => setSoTien(50)}>
                    50
                  </div>
                  <div className="item3" onClick={() => setSoTien(100)}>
                    100
                  </div>
                  <div className="item4" onClick={() => setSoTien(300)}>
                    300
                  </div>
                  <div className="item5" onClick={() => setSoTien(500)}>
                    500
                  </div>
                  <div className="item6" onClick={() => setSoTien(800)}>
                    800
                  </div>
                </div>

                <div className="footer-top-right">
                  <input
                    type="number"
                    value={soTien}
                    onChange={(e: any) => setSoTien(e.target.value)}
                  />
                </div>
              </div>

              <div className="footer-bottom d-flex">
                <div>
                  <div style={{ fontSize: 14 }}>
                    <span style={{ color: '#fe6464' }}>{chonCuoc.length}</span>{' '}
                    đơn, Tổng:{' '}
                    <span style={{ color: '#fe6464' }}>
                      {formatVND(chonCuoc.length * soTien)}
                    </span>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    Tổng số dư:{' '}
                    <span style={{ color: '#fe6464' }}>
                      {formatVND(user?.money ?? 0)}
                    </span>
                  </div>
                </div>

                <div className="button-submit">
                  <div className="x3">X3</div>
                  <div
                    className={chonCuoc.length ? 'submit active' : 'submit'}
                    onClick={() => handleOnShow()}
                  >
                    Xác nhận ngay
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {tab == 2 && <div className="tab tab2"></div>}

        {tab == 3 && (
          <div className="tab tab3">
            <div className="header-class d-flex">
              <div className="w-25 text-center">Số kỳ</div>
              <div className="w-75 text-center">Kết quả mở thưởng</div>
            </div>

            <div className="list-content-sc">
              {listKetQua?.map((item, index) => {
                return (
                  <div className="content-class d-flex" key={index}>
                    <div
                      className="w-25 text-center"
                      style={{ color: '#89629a' }}
                    >
                      {item.id}
                    </div>
                    <div className="w-75">
                      <div className="d-flex item-bi-quay justify-content-between px-3 align-items-center">
                        <div className={'item item-' + item?.ball1}>
                          {item?.ball1}
                        </div>
                        <div className={'item item-' + item?.ball2}>
                          {item?.ball2}
                        </div>
                        <div className={'item item-' + item?.ball3}>
                          {item?.ball3}
                        </div>
                        <div className={'item item-' + item?.ball4}>
                          {item?.ball4}
                        </div>
                        <div className={'item item-' + item?.ball5}>
                          {item?.ball5}
                        </div>
                        <div className={'item item-' + item?.ball6}>
                          {item?.ball6}
                        </div>
                        <div className={'item item-' + item?.ball7}>
                          {item?.ball7}
                        </div>
                        <div className={'item item-' + item?.ball8}>
                          {item?.ball8}
                        </div>
                        <div className={'item item-' + item?.ball9}>
                          {item?.ball9}
                        </div>
                        <div className={'item item-' + item?.ball10}>
                          {item?.ball10}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {tab == 4 && (
          <div className="tab tab4">
            <div className="box-list pb-4">
              {historys?.map((i: IHistory, index: number) => {
                return (
                  <div className="item mt-2" key={index}>
                    <div className="header-item px-3 py-2 d-flex justify-content-between">
                      <div className="title">{i.biDatCuoc.game?.name}</div>
                      <div className="time">Kỳ: {i.KyId}</div>
                    </div>
                    <div className="content-item px-3 py-2">
                      <div className="item">
                        <div className="name mb-3">
                          {i?.biDatCuoc?.gameSidebar?.name} - {i.biDatCuoc.name}
                        </div>
                        <div className="rate mb-2 d-flex justify-content-between">
                          <div>Đặt cược: {formatVND(i?.money ?? 0)}</div>
                          {renderKQ(i)}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>

      <div>
        <SModal
          alignment="center"
          visible={showModalCuoc}
          backdrop="static"
          className="custom-modal-game custom-modal-datcuoc"
        >
          <CModalBody>
            <div>
              <div className="d-flex justify-content-between mb-3">
                <div className="fw-bold">Chi tiết đặt cược</div>
                <div>Thời hạn: 5 phút</div>
              </div>

              <div className="px-3 mt-4">
                <div className="mb-1"> Kỳ: {ky?.id}</div>
                <div className="mb-1"> Chi tiết:</div>
                <div className="border p-2" style={{ fontSize: 12 }}>
                  {chonCuoc.map((item: IBiDatCuoc, index: number) => {
                    return (
                      <div key={index}>
                        {mapBiDatCuocGroup[item?.group ?? 1]} - {item.name} - Số
                        tiền:{' '}
                        <strong style={{ color: '#0d8ea7' }}>
                          {formatVND(Number(soTien))}
                        </strong>{' '}
                      </div>
                    )
                  })}
                </div>

                <div className="mt-3">
                  Tổng tiền:{' '}
                  <strong style={{ color: '#0d8ea7' }}>
                    {formatVND(Number(soTien) * chonCuoc.length)}
                  </strong>
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter className="d-flex justify-content-around">
            <div
              className="cancel fw-bold"
              onClick={() => setShowModalCuoc(false)}
            >
              Đóng
            </div>

            <div className="submit fw-bold" onClick={submitHanler}>
              Xác nhận
            </div>
          </CModalFooter>
        </SModal>
      </div>
    </div>
  )
}

export default EsportF1
