import Layout from './Layout'
import { NavLink } from 'react-router-dom'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { logout, resetAll } from '../auth/auth.reducer'
import {
  CButton,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import account1 from '../../../assets/img/ac1.png'
import account2 from '../../../assets/img/ac2.png'
import account3 from '../../../assets/img/ac3.png'
import account4 from '../../../assets/img/ac4.png'
import { formatVND } from '@/shared/utils/ultils'
import avatar from '../../../assets/img/avatar_2.jpg'
import axios from '../../../shared/config/axios-interceptor'

const Account = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [tab, setTab] = useState(1)

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }


  const [data, setData] = useState<any>({})

  const getKetQua = async () => {
    try {
      const response = await axios.get('/history/lai-lo')
      setData(response.data)
    } catch (error) {
      setData([])
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKetQua()
  }, [])

  console.log(data);
  
  return (
    <Layout>
      <div className="box-account-wap">
        <div className="header-top">
          <div className="top text-center">
            <img src={avatar} className="avatar  mt-3 mb-2" />
            <div style={{ color: '#fff', fontSize: 14 }}>{user?.username}</div>
            <div style={{ color: '#fff', fontSize: 14 }}>
              Biệt danh: {user?.username}
            </div>
            <div>
              <span style={{ color: '#fbe53a', fontSize: 12 }}>VIP{user?.vip ?? 1}</span>
            </div>
          </div>

          <div className="bottom d-flex justify-content-between align-items-center">
            <div style={{ color: '#fff', fontSize: 14 }} className="left">
              <div>
                Lãi lỗ: <span style={{ color: '#fbe53a' }}>{formatVND(data?.total)}</span>
              </div>
              <div>
                Tiền đặt: <span style={{ color: '#fbe53a' }}>{formatVND(data?.moneyTotal)}</span>
              </div>
            </div>
            <div
              className="text-end right"
              style={{ color: '#fff', fontSize: 14 }}
            >
              Số dư:{' '}
              <span style={{ color: '#fbe53a' }}>
                {formatVND(user?.money ?? 0)}
              </span>
              <div className="mt-1 box-nap-rut d-flex justify-content-end gap-2">
                <NavLink className="text-decoration-none" to={'/message'}>
                  Nạp tiền
                </NavLink>
                <NavLink className="text-decoration-none" to={'/withdraw'}>
                  Rút tiền
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content-account">
          <div className="tab-header">
            <div
              className={
                tab == 1 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(1)}
            >
              <img src={account1} />
              <div>Tài khoản</div>
            </div>

            <div
              className={
                tab == 2 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(2)}
            >
              <img src={account2} />
              <div>Báo cáo</div>
            </div>

            <div
              className={
                tab == 3 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(3)}
            >
              <img src={account3} />
              <div>Đội ngũ</div>
            </div>

            <div
              className={
                tab == 4 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(4)}
            >
              <img src={account4} />
              <div>Hệ thống</div>
            </div>
          </div>

          <div className="tab-content">
            {tab == 1 && (
              <div className="tab1">
                <NavLink to="/account-info">
                  <span>Thông tin cơ bản</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/history-withdraw">
                  <span>Lịch sử rút tiền</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/message'}>
                  <span>Nạp tiền</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/withdraw'}>
                  <span>Rút tiền</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/history-withdraw">
                  <span>Tra cứu số dư</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 2 && (
              <div className="tab1">
                <NavLink to="/history-order">
                  <span>Lịch sử cược</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/lai-lo">
                  <span>Lãi lỗ hôm nay</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                {/* <NavLink to="/history-order">
                  <span>Lịch sử nạp</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/history-order'}>
                  <span>Lịch sử rút</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink> */}
              </div>
            )}

            {tab == 3 && (
              <div className="tab1">
                <NavLink to="/magioithieu">
                  <span>Mã giới thiệu</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 4 && (
              <div className="tab1">
                <NavLink to="/uudai">
                  <span>Ưu đãi</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/uudai">
                  <span>Tin tức</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/message'}>
                  <span>CSKH</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}
          </div>

          <div className="logout">
            <CButton onClick={onLogout}>Đăng xuất</CButton>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Account
