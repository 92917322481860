import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../../shared/model/user.model';
import { forgotPassword, getProfile, login, changePassword, updateProfile, register } from './auth.api';

interface IInitialLoginState {
  loading: boolean;
  errorMessage: string | null;
  message: string | null;
  user: IUser | null;
  loginSuccess: boolean;
  popup: boolean;
  getProfileSuccess: boolean;
  token: string | null;
  firstTimeLogin: boolean;
  forgotPasswordSuccess: boolean;
  changePasswordSuccess: boolean;
  updateProfileSuccess: boolean;
}

const initialState: IInitialLoginState = {
  loading: false,
  errorMessage: null,
  loginSuccess: false,
  popup: false,
  getProfileSuccess: false,
  token: null,
  user: null,
  firstTimeLogin: false,
  forgotPasswordSuccess: false,
  changePasswordSuccess: false,
  updateProfileSuccess: false,
  message: null,
};

const { actions, reducer } = createSlice({
  name: 'authenticationSlice',
  initialState,
  reducers: {
    fetching(state) {
      state.loading = true;
    },
    setPopup(state) {
      state.popup = false;
    },
    logout(state) {
      state.token = null;
      state.user = null;
      state.message = null;
      state.loginSuccess = false;
      state.firstTimeLogin = false;
      localStorage.removeItem('authentication_token');
      localStorage.removeItem('first_login');
    },
    resetAll(state) {
      state.loading = false;
      state.loginSuccess = false;
      state.getProfileSuccess = false;
      state.token = null;
      state.user = null;
      state.errorMessage = null;
      state.firstTimeLogin = false;
      state.forgotPasswordSuccess = false;
      state.changePasswordSuccess = false;
      state.updateProfileSuccess = false;
    },
    resetEntity(state) {
      state.getProfileSuccess = false;
      state.loginSuccess = false;
      state.loading = false;
      state.errorMessage = null;
      state.forgotPasswordSuccess = false;
      state.changePasswordSuccess = false;
      state.updateProfileSuccess = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      login.fulfilled,
      (state, { payload }: PayloadAction<{ access_token: string; firstTimeLogin: boolean }>) => {
        localStorage.setItem('authentication_token', payload.access_token);
        localStorage.setItem('first_login', String(payload.firstTimeLogin));
        state.token = payload.access_token;
        state.firstTimeLogin = payload.firstTimeLogin;
        state.loginSuccess = true;
        state.popup = true;
        state.loading = false;
      }
    );
    builder.addCase(login.rejected, (state, { payload }: PayloadAction<any>) => {
      localStorage.removeItem('authentication_token');
      localStorage.removeItem('first_login');
      state.errorMessage = payload?.code;
      state.loading = false;
      state.loginSuccess = false;
      state.popup = false;
    });
    builder.addCase(
      register.fulfilled,
      (state, { payload }: PayloadAction<{ code: string; message: string }>) => {
        // localStorage.setItem('authentication_token', payload.access_token);
        // localStorage.setItem('first_login', String(payload.firstTimeLogin));
        // state.token = payload.access_token;
        // state.firstTimeLogin = payload.firstTimeLogin;
        state.message = payload.message;
        state.loginSuccess = false;
        state.loading = false;
      }
    );
    builder.addCase(register.rejected, (state, { payload }: PayloadAction<any>) => {
      localStorage.removeItem('authentication_token');
      localStorage.removeItem('first_login');
      state.errorMessage = payload?.code;
      state.loading = false;
      state.loginSuccess = false;
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.getProfileSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    });
    builder.addCase(getProfile.rejected, (state, { payload }: PayloadAction<any>) => {
      localStorage.removeItem('authentication_token');
      localStorage.removeItem('first_login');
      state.getProfileSuccess = false;
      state.errorMessage = payload?.code;
      state.loading = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.forgotPasswordSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }: PayloadAction<any>) => {
      state.forgotPasswordSuccess = false;
      state.errorMessage = payload?.code;
      state.loading = false;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.changePasswordSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    });
    builder.addCase(changePassword.rejected, (state, { payload }: PayloadAction<any>) => {
      state.changePasswordSuccess = false;
      state.errorMessage = payload?.code;
      state.loading = false;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.updateProfileSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    });
    builder.addCase(updateProfile.rejected, (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.code;
      state.updateProfileSuccess = false;
      state.loading = false;
    });
  },
});

export const { fetching, resetAll, resetEntity, logout, setPopup } = actions;
export default reducer;
