import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import bottomWhite from '../../../../assets/img/bottom-white.png'
import { CCol, CRow } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import axios from '../../../../shared/config/axios-interceptor'
import { IGameType } from '@/shared/model/gameType.model'
import { NavLink } from 'react-router-dom'

interface IProp {
  game: any
}
const Header = (props: IProp) => {
  const { game } = props
  const { navigate } = useRouter()
  const [gameType, setGameType] = useState<IGameType[]>([])

  const [tab, setTab] = useState(false)

  const getGameType = async () => {
    try {
      const response = await axios.get(
        '/game-type?status=ACTIVE&sortBy=order&sortOrder=ASC&statusGame=ACTIVE',
      )
      setGameType(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    setTab(false)
  }, [game])

  useEffect(() => {
    getGameType()
  }, [])

  return (
    <div className="header-top d-flex justify-content-between align-item-center header-top-wap-game">
      <NavLink to={'/'}>
        <ArrowLeft />
      </NavLink>
      <div className="title" style={{ fontSize: 14 }}>
        {game?.name}
      </div>
      <div></div>
    </div>
  )
}

export default Header
