import { RootState } from '@/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useEffect, useState } from 'react'
import { socket } from '@/shared/config/socket'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import SModal from '@/components/shared/Modal/SModal'
import {
  CButton,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import { ITransaction } from '@/shared/model/transaction.model'
import axios from '../../../shared/config/axios-interceptor'
import qua1 from '../../../assets/img/qua1.png'
import MenuBottom from './MenuBottom'
import { logout, setPopup } from '../auth/auth.reducer'
import imageNottiUp from '../../../assets/img/noti-up.png'

const token =
  localStorage.getItem('authentication_token') ||
  sessionStorage.getItem('authentication_token')

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { navigate, location } = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)
  const [visible, setVisible] = useState(false)
  const [visibleLogin, setVisibleLogin] = useState(false)
  const [deposit, setDeposit] = useState<ITransaction>()

  const { popup } = useSelector((state: RootState) => state.authentication)

  const [notifiFirstLogin, setNotifiFirstLogin] = useState<any>(null)

  const getNofitiFirstLogin = async () => {
    try {
      const response = await axios.get('/config/1')
      setNotifiFirstLogin(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getNofitiFirstLogin()
    console.log('data1231323', user?.id)

    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg)
    })
    console.log('data', user?.id)

    socket.on(`user_${user?.id}_new_deposit`, (data: any) => {
      console.log('data', data)

      if (data) {
        dispatch(getProfile())
      }

      if (data && data.isNotify) {
        setDeposit(data)
        setVisible(true)
      }
    })

    socket.on('send_data', (res) => {
      if(res?.action == 'INACTIVE' && res?.id == user?.id){
        dispatch(logout());
      }
    });

    return () => {
      // console.log('disconnect');
      // socket.off('connect')
      socket.off(`user_${user?.id}_new_message`)
      socket.off(`user_${user?.id}_new_deposit`)
      socket.off(`send_data`)
      // socket.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClose = () => {
    setVisible(false)
  }

  const handleOnCloseLogin = () => {
    dispatch(setPopup())
  }
  return (
    <>
      <div className="container-wap">
        {children}
        {/* <div className="info-current-user d-flex justify-content-between align-items-center">
          <div className="fw-semibold">ID: {user?.id}</div>
          <div className="fw-semibold">
            Số dư: {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}
          </div>
        </div> */}
        <MenuBottom />
      </div>
      <div>
        <SModal
          visible={visible}
          onClose={handleOnClose}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          {/* <CModalHeader>
            <h5>Thông báo Nạp Tiền</h5>
          </CModalHeader> */}
          <CModalBody>
            <div className="text-center">
              <img width={120} src={qua1} />
            </div>

            <h1 className="text-center my-2">Thông báo</h1>
            <p style={{ color: 'gray' }} className="mt-3">
              {deposit?.message}
            </p>
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton onClick={handleOnClose} style={{ background: '#FD0404' }}>
              Đồng ý
            </CButton>
          </CModalFooter>
        </SModal>
      </div>
    </>
  )
}

export default Layout
