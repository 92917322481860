import { CCol, CContainer, CRow } from '@coreui/react-pro'
import bot1 from '../../../assets/img/bot1.png'

const FooterCustom = () => {
  return (
    <div style={{ background: '#f8f8f8' }} className="py-5 mt-3">
      <CContainer>
        <CRow>
          <CCol xs={3}>
            <h3 className="fw-bold" style={{ fontSize: 18 }}>
              Hỗ trợ kỹ thuật
            </h3>
            <img src={bot1} />

            <div>Lottery lucky hệ thống</div>
            <div>Nền tảng hệ thống xổ số chuyên nghiệp</div>
          </CCol>
          <CCol>
            <div className="fw-bold" style={{ fontSize: 18 }}>
              Kinh nghiệm dịch vụ
            </div>
            <div>Thời gian trung bình nạp tiền vào tài khoản ngày hôm nay</div>
            <div>Thời gian trung bình rút tiền và đến ngày hôm nay</div>
          </CCol>
        </CRow>

        <div className="mt-5">
          <div className="container text-center">
            <p style={{ fontSize: 15 }}>
              Quan điểm chúng tôi | Liên hệ chúng tôi | Hợp tác kinh doanh |
              Pháp lý thực tế | Cam kết bảo mật
            </p>
            <p style={{ fontSize: 15 }}>Copyright © Lottery lucky | 18+</p>
          </div>
        </div>
      </CContainer>
    </div>
  )
}

export default FooterCustom
