import { NavLink } from "react-router-dom"

const Nav = () => {
    return <>
        <div className="header-top d-flex align-items-center" style={{ background: '#fff' }}>
            <NavLink to={'/tai-khoan'} className="px-2">Tài khoản</NavLink>
            <NavLink to={'/rut-tien'} className="px-2">Rút tiền</NavLink>
            <NavLink to={'/lich-su-rut-tien'} className="px-2">Lịch sử rút tiền</NavLink>
            <NavLink to={'/lich-su-nap-tien'} className="px-2">Lịch sử nạp tiền</NavLink>
            <NavLink to={'/lich-su-dat-cuoc'} className="px-2">Lịch sử đặt cược</NavLink>
            <NavLink to={'/lai-lo'} className="px-2">Lãi lỗ hôm nay</NavLink>

            {/* <NavLink to={'/'} className="px-2">Báo cáo</NavLink>
                <NavLink to={'/'} className="px-2">Đội ngũ</NavLink>
                <NavLink to={'/'} className="px-2">Ưu đãi</NavLink>
                <NavLink to={'/'} className="px-2">Tin tức</NavLink> */}
        </div>
    </>

}

export default Nav