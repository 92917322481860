import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import Pk10 from './Pk10'
import { IGameName } from '@/shared/model/gameName.model'

const Game = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const [game, setGame] = useState<IGameName | null>(null)

  const getGame = async (id: number) => {
    try {
      const response = await axios.get('/game/' + id)
      setGame(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getGame(Number(params?.id) ?? 1)
  }, [params?.id])

  if (!game) return <></>

  // Game PK
  if (game.group === 1) {
    return <Pk10 />
  }

  // Game Lotte Bet
  if (game.group === 2) {
    return <Pk10 />
  }

  // EsportF1
  if (game.group === 3) {
    return <Pk10 />
  }

  if (game.group === 3) {
    return <Pk10 />
  }


  if (game.group === 4) {
    return <Pk10 />
  }


  if (game.group === 5) {
    return <Pk10 />
  }


  if (game.group === 6) {
    return <Pk10 />
  }


  if (game.group === 7) {
    return <Pk10 />
  }


  if (game.group === 8) {
    return <Pk10 />
  }


  if (game.group === 9) {
    return <Pk10 />
  }


  if (game.group === 10) {
    return <Pk10 />
  }

  return <Pk10 />

}

export default Game
