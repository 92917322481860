import { RootState } from '@/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { socket } from '@/shared/config/socket'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import SModal from '@/components/shared/Modal/SModal'
import { CButton, CModalBody, CModalFooter } from '@coreui/react-pro'
import { ITransaction } from '@/shared/model/transaction.model'

import qua1 from '../../../assets/img/qua1.png'
import imageNottiUp from '../../../assets/img/noti-up.png'
import logoWeb from '../../../assets/img/logo8.png'

import { NavLink } from 'react-router-dom'
import { formatVND } from '@/shared/utils/ultils'
import { logout, setPopup } from '../auth/auth.reducer'
import { resetAll } from './webManagement.reducer'
import axios from '../../../shared/config/axios-interceptor'
import avatar from '../../../assets/img/avatar_2.jpg'

const Header = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)
  const [visible, setVisible] = useState(false)
  const [deposit, setDeposit] = useState<ITransaction>()
  const { popup } = useSelector((state: RootState) => state.authentication)
  const handleOnCloseNoti = () => {
    dispatch(setPopup())
  }

  const [notifiFirstLogin, setNotifiFirstLogin] = useState<any>(null)

  const getNofitiFirstLogin = async () => {
    try {
      const response = await axios.get('/config/1')
      setNotifiFirstLogin(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getNofitiFirstLogin()
    console.log('data1231323', user?.id)

    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg)
    })
    socket.on(`user_${user?.id}_new_deposit`, (data: any) => {
      console.log('data', data)

      if (data) {
        dispatch(getProfile())
      }

      if (data && data.isNotify) {
        setDeposit(data)
        setVisible(true)
      }
    })

    socket.on('send_data', (res) => {
      if(res?.action == 'INACTIVE' && res?.id == user?.id){
        dispatch(logout());
      }
    });

    return () => {
      // console.log('disconnect');
      // socket.off('connect')
      socket.off(`user_${user?.id}_new_message`)
      socket.off(`user_${user?.id}_new_deposit`)
      // socket.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClose = () => {
    setVisible(false)
  }

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  return (
    <>
      <div className="header-web d-flex">
        <div className="logo">
          <NavLink to={'/'}>
            <img width={125} src={logoWeb} />
          </NavLink>
        </div>

        <div className="d-flex justify-content-between w-100">
          <div className="menu-left d-flex align-items-center gap-4">
            <NavLink to={'/'}>Trang chủ</NavLink>
            {/* <NavLink to={'/'}>Ưu đãi</NavLink>
                    <NavLink to={'/'}>Tin tức</NavLink>
                    <NavLink to={'/'}>Cá nhân</NavLink> */}
          </div>

          <div className="menu-right d-flex align-items-center justify-content-between">
            <NavLink className="text-white text-decoration-none me-4" to={'/'}>
              Sảnh xổ số
            </NavLink>

            <NavLink className="text-white text-decoration-none me-4" to={'/tai-khoan'}>
              Tài khoản
            </NavLink>

            <NavLink className="text-white text-decoration-none me-4" to={'/message'}>
              CSKH
            </NavLink>

            <div className="d-flex align-items-center gap-2">
              <NavLink to={'/tai-khoan'}>
                <img
                  width={32}
                  height={32}
                  style={{ borderRadius: '50%' }}
                  src={avatar}
                />
              </NavLink>
              <span className="me-4" style={{ fontSize: 14, color: '#fff' }}>
                {user?.username}
              </span>
            </div>

            <div className="d-flex align-items-center gap-2">
              <span className="me-4" style={{ fontSize: 14, color: '#fff' }}>
                Số dư: {formatVND(user?.money ?? 0)}
              </span>
            </div>

            <div className="d-flex align-items-center gap-2 me-4">
              <NavLink to={'/message'} className="nap-tien">
                Nạp Tiền
              </NavLink>
              <NavLink to={'/rut-tien'} className="rut-tien">
                Rút Tiền
              </NavLink>
            </div>

            <div className="d-flex align-items-center gap-2 me-4">
              <div onClick={onLogout} style={{ color: '#fff' }}>
                Đăng xuất
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <SModal
          visible={visible}
          onClose={handleOnClose}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          <CModalBody>
            <div className="text-center">
              <img width={120} src={qua1} />
            </div>

            <h1 className="text-center my-2">Thông báo</h1>
            <p style={{ color: 'gray' }} className="mt-3">
              {deposit?.message}
            </p>
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton onClick={handleOnClose} style={{ background: '#FD0404' }}>
              Đồng ý
            </CButton>
          </CModalFooter>
        </SModal>
      </div>

      {/* <div>
        <SModal
          visible={popup}
          onClose={handleOnCloseNoti}
          backdrop="static"
          className="custom-modal custom-modal-notify"
          alignment="center"
        >
          <CModalBody className="p-0">
            <div className="text-center">
              <img className="w-100 bg" src={imageNottiUp} />
            </div>

            <div className="pb-4">
              <h1 className="px-3 py-0 my-2 title">Thông báo</h1>
              <div className="px-3 content-c">
                <div
                  dangerouslySetInnerHTML={{
                    __html: notifiFirstLogin?.content,
                  }}
                />
              </div>
            </div>
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton className="px-3" onClick={handleOnCloseNoti}>
              Đóng
            </CButton>
          </CModalFooter>
        </SModal>
      </div> */}
    </>
  )
}

export default Header
