import { NavLink } from 'react-router-dom'
import home from '../../../assets/img/home.png'
import uuDai from '../../../assets/img/uu-dai.png'
import cskh from '../../../assets/img/cksh.png'
import caNhan from '../../../assets/img/ca-nhan.png'

const MenuBottom = () => {
  return (
    <div className="menu-bottom-wap d-flex justify-content-between align-items-center">
      <NavLink
        to={'/'}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img width={28} src={home} />
        <span>Trang chủ</span>
      </NavLink>
      <NavLink
        to={'/uudai'}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img width={28} src={uuDai} />
        <span>Đại sảnh</span>
      </NavLink>
      <NavLink
        to={'/message'}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img width={30} height={27} src={cskh} />
        <span>CSKH</span>
      </NavLink>
      <NavLink
        to={'/account'}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img width={27} src={caNhan} />
        <span>Cá nhân</span>
      </NavLink>
    </div>
  )
}

export default MenuBottom
