import SModal from '@/components/shared/Modal/SModal'
import {
  CButton,
  CCol,
  CModalBody,
  CModalFooter,
  CRow,
} from '@coreui/react-pro'
import { set } from 'lodash'
import { useState } from 'react'
import edit from '../../../../assets/img/edit.png'

interface IProp {
  soTien: number
  setSoTien: any
}

const BoxTienCuoc = (props: IProp) => {
  const { soTien, setSoTien } = props

  let listTien = [
    {
      name: 10,
      value: 10,
    },
    {
      name: 50,
      value: 50,
    },
    {
      name: 100,
      value: 100,
    },
    {
      name: 200,
      value: 200,
    },
    {
      name: 500,
      value: 500,
    },
  ]

  const getListBylocalStorage = localStorage.getItem('listTien');
  if(getListBylocalStorage){
    listTien = JSON.parse(getListBylocalStorage);
  }


  const [listTienChip, setListTienChip] = useState<any>(listTien)
  const [listTmp, setListTmp] = useState<any>(listTien)


  const chinhSuaSoTien = [
    {
      name: 10,
      value: 10,
    },
    {
      name: 50,
      value: 50,
    },
    {
      name: 100,
      value: 100,
    },
    {
      name: 200,
      value: 200,
    },
    {
      name: 500,
      value: 500,
    },

    {
      name: '1k',
      value: 1000,
    },

    {
      name: '2k',
      value: 2000,
    },

    {
      name: '5k',
      value: 5000,
    },

    {
      name: '10k',
      value: 10000,
    },

    {
      name: '20k',
      value: 20000,
    },
    {
      name: '50k',
      value: 50000,
    },
    {
      name: '100k',
      value: 100000,
    },
  ]

  //   localStorage.setItem("mytime",);

  const [popup, setPopup] = useState(false)
  const handleOnClose = () => {
    setPopup(false)
  }

  const handlerSave = (item: any) => {
    if (listTmp.some((t: any) => t.value === item.value)) {
      const list = listTmp.filter((t: any) => t.value !== item.value)
      setListTmp(list)
    } else {
      if (listTmp.length < 5) {
        setListTmp([...listTmp, item])
      }
    }
  }

  const handleSave = () => {
    listTmp.sort((a: any, b: any) => a.value - b.value)
    setListTienChip(listTmp)
    localStorage.setItem('listTien', JSON.stringify(listTmp))
    setPopup(false)
  }
  return (
    <>
      <div className="footer-top">
        <div className="footer-top-left">
          {listTienChip.map((item: any, index: number) => (
            <div
              key={index}
              className={'item' + (index + 1)}
              onClick={() => setSoTien(item.value)}
            >
              {item.name}
            </div>
          ))}

          <div>
            <img width={20} src={edit} onClick={() => setPopup(true)} />
          </div>
        </div>

        <div className="footer-top-right">
          <input
            type="number"
            value={soTien}
            onChange={(e: any) => setSoTien(e.target.value)}
          />
        </div>
      </div>
      <div>
        <SModal
          visible={popup}
          onClose={handleOnClose}
          backdrop="static"
          className="custom-modal custom-modal-catdat-tien custom-modal-catdat-tien-mobile"
          alignment="center"
        >
          <CModalBody className="p-0">
            <div className="pb-4">
              <p
                className="pt-2 pb-3 my-2 title border-bottom text-center"
                style={{ fontSize: 16 }}
              >
                Chỉnh sửa số tiền
              </p>

              <div className="content-b px-3">
                <CRow className="g-4">
                  {chinhSuaSoTien.map((item, index) => {
                    return (
                      <CCol
                        key={index}
                        xs={3}
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => handlerSave(item)}
                      >
                        <div
                          className={
                            listTmp.some((t: any) => t.value === item.value)
                              ? 'item-cuoc active'
                              : 'item-cuoc'
                          }
                        >
                          {item.name}
                        </div>
                      </CCol>
                    )
                  })}
                </CRow>
              </div>
            </div>
          </CModalBody>
          <CModalFooter className="justify-content-center border-top py-3">
            <CButton
              style={{ width: 100, background: '#f0f0f0', color: '#000' }}
              className="px-3"
              onClick={handleOnClose}
            >
              Hủy
            </CButton>
            <CButton
              style={{ width: 100, background: '#f49400' }}
              className="px-3"
              onClick={handleSave}
            >
              Lưu lại
            </CButton>
          </CModalFooter>
        </SModal>
      </div>
    </>
  )
}

export default BoxTienCuoc
